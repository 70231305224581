import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import queryClient from '../queryClient';
import { DealsIdeasType } from '../types';
import { useGetUserData } from './useGetUserData';

export function useGetDealsIdeas(): UseQueryResult<DealsIdeasType[]> {
  const { data: userData } = useGetUserData();
  const queryFn = async (): Promise<DealsIdeasType[]> => {
    const result = await axios.get(`/api/dashboard/getDealsIdeas`);
    return result.data;
  };

  return useQuery<DealsIdeasType[], Error>(`deals-ideas`, () => queryFn(), {
    enabled: !!userData?.isAdmin,
    onSuccess: () => {
      queryClient.refetchQueries('user-data');
      queryClient.refetchQueries('site-metrics');
    },
    onError: () => {
      console.error('Something went wrong while fetching the deals ideas');
    }
  });
}

export function useBulkBanDealsIdea() {
  interface RequestData {
    ASINs: string[];
    days: number;
  }

  const mutationFn = async ({ ASINs, days }: RequestData): Promise<void> => {
    return axios.post(`/api/dashboard/bulkBanDealsIdea`, {
      ASINs,
      days
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('deals-ideas');
    },
    onError: () => {
      console.error('error bulk banning deals ideas');
    }
  });
}

export interface RequestDataBulkAddToys {
  ASINs: Array<{
    ASIN: string;
    price: number;
  }>;
  shouldAddMainDB: boolean;
  shouldRemoveMainDB?: boolean;
}

export function useBulkAddToys() {
  const mutationFn = async ({
    ASINs,
    shouldAddMainDB,
    shouldRemoveMainDB = false
  }: RequestDataBulkAddToys): Promise<void> => {
    const toyDeals: {
      [key: string]: number;
    } = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ASINs.length; i++) {
      toyDeals[ASINs[i].ASIN] = ASINs[i].price;
    }

    return axios.post(`/api/dashboard/bulkAddToys`, {
      toyDeals,
      shouldAddMainDB,
      shouldRemoveMainDB
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('deals-ideas');
    },
    onError: () => {
      console.error('error bulk banning deals ideas');
    }
  });
}

export function useBulkAddASINCouponDeal() {
  interface RequestData {
    ASINs: Array<{
      ASIN: string;
      price: number;
    }>;
  }

  const mutationFn = async ({ ASINs }: RequestData): Promise<void> => {
    await axios.post('/api/dashboard/bulkAddASINCouponDeal', {
      ASINs
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('deals-ideas');
    },
    onError: () => {
      console.error('error bulk banning deals ideas');
    }
  });
}
